import React from "react"
import { useEffect } from "react"
import Recaptcha from "react-google-recaptcha"

const ContactForm = () => {
    useEffect(() => {
        let bgReferrer = localStorage.getItem("bgReferrer");
        if (bgReferrer) {
            let referrer = JSON.parse(bgReferrer);
            document.getElementById('contactSourceField').value = referrer.value;
        }
    });

    const [nameError, setNameError] = React.useState("")
    const [contactError, setContactError] = React.useState("")
    const [messageError, setMessageError] = React.useState("")
    const [captcahError, setCaptcahError] = React.useState("")

    const recaptchaRef = React.createRef()
    const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY


    return (
        <div className="pt-16" id="contactForm">
            <div className="bg-gray-900 p-8">
                <form
                    name="contacts"
                    id="contacts"
                    method="POST"
                    action="/firm-overview/thank-you/"
                    data-netlify="true"
                    netlify-honeypot="bot-field"
                    onSubmit={event => {
                        if (RECAPTCHA_KEY && !recaptchaRef.current.getValue()) {
                            event.preventDefault()
                            setCaptcahError("* Captcha is required")
                        }
                        if (event.target.name.value.trim().length < 1) {
                            setNameError("* Name is required")
                            event.preventDefault()
                        } else {
                            setNameError("")
                        }
                        if (
                            event.target.phone.value.trim().length < 7 &&
                            event.target.email.value.trim().length < 7
                        ) {
                            setContactError("* Email or Phone is required")
                            event.preventDefault()
                        } else {
                            setContactError("")
                        }
                        if (event.target.message.value.trim().length < 1) {
                            setMessageError("* Description is required")
                            event.preventDefault()
                        } else {
                            setMessageError("")
                        }
                    }}
                >

                    <p class="hidden">
                        <label>
                            Don't fill this out if you're human: <input name="bot-field" />
                        </label>
                    </p>

                    <input type="hidden" name="form-name" value="contacts" />
                    <input type="hidden" name="source" id="contactSourceField" value="bartlettgrippe.com" />
                    <label className="block text-white text-md mb-2">
                        Name{" "}
                        {nameError.length ? (
                            <div className="text-red-500">{nameError}</div>
                        ) : null}
                        <input
                            type="text"
                            className="text-gray-900 rounded w-full mb-4 p-2 max-h-9"
                            name="name"
                            id="contacts-name"
                        />
                    </label>
                    <label className="block text-white text-md mb-2">
                        Phone{" "}
                        {contactError.length ? (
                            <div className="text-red-500">{contactError}</div>
                        ) : null}
                        <input
                            type="text"
                            className="text-gray-900 rounded w-full mb-4 p-2 max-h-9"
                            name="phone"
                            id="contacts-phone"
                        />
                    </label>
                    <label className="block text-white text-md mb-2">
                        Email{" "}
                        {contactError.length ? (
                            <div className="text-red-500">{contactError}</div>
                        ) : null}
                        <input
                            type="text"
                            className="text-gray-900 rounded w-full mb-4 p-2 max-h-9"
                            name="email"
                            id="contacts-email"
                        />
                    </label>
                    <label className="block text-white text-md mb-2">
                        Description{" "}
                        {messageError.length ? (
                            <div className="text-red-500">{messageError}</div>
                        ) : null}
                        <textarea
                            type="text"
                            className="text-gray-900 rounded w-full mb-2 p-2 h-16"
                            name="message"
                            id="contacts-message"
                        />
                    </label>
                    {captcahError.length ? (
                        <label className="block text-white text-lg mb-2">
                            <div className="text-red-500">{captcahError}</div>
                        </label>
                    ) : null}
                    {RECAPTCHA_KEY && (
                        <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
                    )}
                    <button
                        type="submit"
                        class="block bg-green-400 text-white rounded py-1 pl-2 pr-3 font-semibold"
                    >
                        <svg
                            class="w-8 inline mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokelinecap="round"
                                strokelinejoin="round"
                                strokewidth="2"
                                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                            ></path>
                        </svg>
                        Get Help Now
                    </button>
                </form>
            </div>
        </div>
    )
}

export default ContactForm