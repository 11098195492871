import React from "react"
import { useEffect } from "react"
import Layout from "../../components/layout-static"
import Seo from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby" // to query for image data
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ContactForm from "../../components/contact-form"
import { Link } from "gatsby"


const Page = () => {
    useEffect(() => {
        const contactPageButton = document.getElementById('contactPageButton');
        contactPageButton.addEventListener('click', e => {
            e.preventDefault();
            const contactForm = document.getElementById('contactForm');
            window.scrollTo({ top: contactForm.offsetTop, behavior: 'smooth' });
        });
    });


    const metadata = {
        title: "Contact Us | Ct Personal Injury Law Firm |  Bartlett & Grippe",
        heroH1: "Injured in a car accident?",
        heroH2: "When experience, integrity, and results matter.",
        useHero: false,
        heroImage: "blg-van-accident.jpg",
        heroImageAlt: "Photo of a desk with a laptop and a phone",
        description:
            "Don't delay, contact us by phone, email, text, or chat for immediate help. We're available 24/7 to assist you and your family. Call now for prompt attention.",
    }

    let heroImage = "law-library.jpg";
    let heroImageAlt = ``;
    if (typeof metadata.heroImage !== 'undefined') {
        heroImage = metadata.heroImage;
    }
    if (typeof metadata.heroImageAlt !== 'undefined') {
        heroImageAlt = metadata.heroImageAlt;
    }


    const data = useStaticQuery(graphql`
    {
      images: allFile(filter: {relativePath: {in: ["photo-wallingford-road.jpg", "photo-bg-staff.jpg", "photo-main-street.jpg", "hero-alcohol.jpg", "hero-motorcycle.jpg", "hero-medmal.jpg", "hero-steps.jpg", "hero-will.jpg", "hero-probate.jpg", "hero-gavel.jpg", "truck-accident-attorney.jpg", "blg-forest-road.jpg", "blg-results.jpg", "blg-contact-us.jpg", "law-library.jpg", "blg-child-dock.jpg", "fabrice-villard-Du41jIaI5Ww-unsplash.jpg", "blg-van-accident.jpg"]}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    `);

    let render = data.images.edges.find(image => image.node.relativePath === heroImage);

    let img = getImage(render.node);

    return (
        <Layout metadata={metadata}>
            <Seo title={metadata.title} description={metadata.description} />
            <div className="grid pt-16 sm:pt-0">
                <GatsbyImage
                    className="xl:h-75vh"
                    image={img}
                    style={{
                        gridArea: "1/1",
                        width: "100%",
                        objectFit: "cover",
                    }}
                    alt={heroImageAlt}
                    formats={["auto", "webp", "avif"]}
                />
                <div style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "center",
                }}
                    className="grid bg-black bg-opacity-50 xl:h-75vh"
                >
                    <div className="relative px-4 2xl:px-0 text-white container mx-auto md:flex items-center gap-8">
                        <div className="w-full md:w-1/2 lg:w-1/2 2xl:w-1/2 pt-16 lg:py-16">
                            {metadata.heroH1.length > 0 ? (
                                <>
                                    <h1 className="uppercase w-full sm:w-full lg:w-full xl:text-7xl font-black f-f-l text-5xl">{metadata.heroH1}</h1>
                                    <div className="sm:pb-0 pt-10 xl:pt-20">
                                        <h2 style={{ lineHeight: '1.25' }} className="font-normal text-white text-3xl lg:text-5xl mb-4"><span className="font-medium text-medium text-green-400">You're in good hands.</span> Reach out to Bartlett & Grippe today for your free consultation.</h2>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="pb-20 sm:pb-0 pt-0 md:pt-80 xl:pt-80">
                                        <h2 className="font-normal text-white text-2xl xs:text-3xl lg:text-3xl mb-4">{metadata.heroH2}</h2>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/2 2xl:w-1/2">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="content"
                className="flex flex-wrap container mx-auto px-4 xl:px-0"
            >
                <div className="w-full pt-8 xl:pt-0">
                    <div className="mb-16">
                        <h2 className="uppercase">Bartlett & Grippe: Your Trusted Car Accident Law Firm</h2>
                        <p className="mb-8">
                            At Bartlett & Grippe, we understand the turmoil and uncertainty that follow a car accident. Our dedicated team of car accident lawyers is here to guide you through every step of the legal process, ensuring you receive the justice and compensation you deserve.
                        </p>
                    </div>
                    <div className="mb-16">
                        <h2 className="uppercase">Why Choose Bartlett & Grippe?</h2>
                        <ul className="list-decimal list-outside ml-6 mb-8">
                            <li><strong>Proven Track Record:</strong>
                                <p>With decades of combined experience, our attorneys have successfully handled countless car accident cases, securing substantial settlements and verdicts for our clients.</p>
                            </li>
                            <li>
                                <strong>Client-Centered Approach:</strong>
                                <p>
                                    We prioritize your needs and concerns, offering personalized legal strategies tailored to the specifics of your case. From the initial consultation to the final resolution, we are committed to providing compassionate and effective representation.
                                </p>
                            </li>
                            <li>
                                <strong>Comprehensive Legal Support:</strong>
                                <p>Our expertise covers all aspects of car accident law, including personal injury, property damage, medical expenses, lost wages, and pain and suffering. We work tirelessly to ensure all your bases are covered.</p>
                            </li>
                            <li>
                                <strong>No Win, No Fee:</strong>
                                <p>We operate on a contingency fee basis, meaning you don’t pay us unless we win your case. This ensures that you can pursue your claim without financial stress.</p>
                            </li>
                            <li>
                                <strong>Aggressive Representation:</strong>
                                <p>We are relentless in our pursuit of justice. Our lawyers are skilled negotiators and formidable litigators who won’t back down until you receive the compensation you deserve.</p>
                            </li>
                        </ul>
                    </div>
                    <div className="mb-16">
                        <h2 className="uppercase">Real Results, Real Relief</h2>
                        <p>At Bartlett & Grippe, we have a history of achieving real results for our clients. We have secured millions of dollars in compensation for our clients.</p>
                    </div>
                    <Link to="/contact-us/" id="contactPageButton">
                        <button className="mb-8 mx-auto cta-button-green">
                            Contact us today for a free confidential consultation
                        </button>
                    </Link>

                </div>
            </div>
        </Layout>
    )
}

export default Page
